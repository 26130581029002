import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="sprk-b-PageTitle sprk-b-TypeDisplayOne sprk-u-mbl sprk-u-Measure">
  Writing Meaningful Web Content with Semantic HTML
    </h1>
    <p>{`Typically, in a word document, writers bold or italicize text to add emphasis to the copy.`}</p>
    <p>{`When producing web content, `}<strong parentName="p">{`HTML elements`}</strong>{` define the structure, purpose and intended meaning of a page's web content.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Main Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Use HTML elements to define the structure and meaning of a web page's content to make it easier for users to understand.`}</li>
      <li parentName="ul">{`Know the purpose of each element to understand when to use it and to ensure the content can be accessed by assistive technologies.`}</li>
      <li parentName="ul">{`Use the correct element to ensure the content communicates the intended meaning.`}</li>
    </ul>
    <p>{`Here are some benefits of using semantic HTML:`}</p>
    <ul>
      <li parentName="ul">{`It allows UX writers and developers to add meaning to content so search engines, screen readers and web browsers can understand it.`}</li>
      <li parentName="ul">{`It improves user experience. It's easier for engineers to understand and to develop with.`}</li>
      <li parentName="ul">{`It helps create faster, responsive, and better mobile user experiences. It's easier to make responsive, have reduced file size, and load faster.`}</li>
      <li parentName="ul">{`It helps web pages get better rankings. Search engines give more importance to those that have them.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`Table of Contents`}</h3>
    <ul>
  <li>
    <a href="#html-elements">HTML Elements</a>
  </li>
  <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#creating-elements-using-tags">Creating Elements Using Tags</a>
    </li>
    <li>
      <a href="#common-html-elements">Common HTML Elements</a>
    </li>
  </ul>
  <li>
    <a href="#semantic-elements">Semantic Elements</a>
  </li>
  <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#the-strong-element">The Strong Element</a>
    </li>
    <li>
      <a href="#strong-vs-bold-vs-css-styling">Strong vs Bold vs CSS Styling</a>
    </li>
    <li>
      <a href="#the-emphasis-element">The Emphasis Element</a>
    </li>
    <li>
      <a href="#emphasis-vs-italic">Emphasis vs Italic</a>
    </li>
  </ul>
  <li>
    <a href="#accessibility">Accessibility</a>
  </li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`HTML Elements`}</h2>
    <p>{`HTML elements wrap content and describe meaning, structure, or context to web browsers, developers, and assistive technologies.`}</p>
    <blockquote>
      <p parentName="blockquote">{`For more information, visit `}<a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element">{` Mozilla's Developer Documentation - HTML elements`}</a>{`.`}</p>
    </blockquote>
    <p>{`As a UX Writer, it's important to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Understand`}</strong>{` what is the purpose of the enclosed text.`}</li>
      <li parentName="ul"><strong parentName="li">{`Know`}</strong>{` what element to use depending on the purpose of the text.`}</li>
      <li parentName="ul"><strong parentName="li">{`Ensure`}</strong>{` we are communicating the intended meaning of the enclosed text to the users.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`Creating Elements Using Tags`}</h3>
    <p>{`Use `}<strong parentName="p">{`HTML tags`}</strong>{` to create an element. These tags represent the start and end of a piece of content.`}</p>
    <p>{`An element consists of three main parts:`}</p>
    <ul>
      <li parentName="ul">{`An `}<strong parentName="li">{`opening tag`}</strong>{` that represents the start of the paragraph element.`}</li>
      <li parentName="ul"><strong parentName="li">{`Content`}</strong>{` within the tags.`}</li>
      <li parentName="ul">{`A `}<strong parentName="li">{`closing tag`}</strong>{` that represents where the element ends.`}</li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Example</h3>
    <pre><code parentName="pre" {...{}}>{`<p>Download the Rocket Homes mobile application on your mobile device to start looking for your dream home.</p>
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The opening tag`}</strong>{` `}<inlineCode parentName="li">{`<p>`}</inlineCode>{`represents the start of the paragraph element.`}</li>
      <li parentName="ul"><strong parentName="li">{`The content`}</strong>{` is the enclosed text inside `}<inlineCode parentName="li">{`<p></p>`}</inlineCode>{` paragraph the tags: `}<i>{`"Download the Rocket..."`}</i></li>
      <li parentName="ul"><strong parentName="li">{`The closing tag`}</strong>{` `}<inlineCode parentName="li">{`</p>`}</inlineCode>{` represents where the paragraph element ends.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`Common HTML Elements`}</h3>
    <blockquote>
      <p parentName="blockquote">{`As a general rule, avoid using `}<strong parentName="p">{`bold`}</strong>{` or `}<strong parentName="p">{`italic`}</strong>{` elements because they do not add semantic meaning.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`strong element`}</strong>{` `}<inlineCode parentName="li">{`<strong></strong>`}</inlineCode>{`, gives extra importance to the element's contents.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`emphasis element`}</strong>{` `}<inlineCode parentName="li">{`<em></em>`}</inlineCode>{`, gives a stressed emphasis to the element's contents.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`bold element`}</strong>{` `}<inlineCode parentName="li">{`<b></b>`}</inlineCode>{` , draws attention to the element's contents, without extra importance.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`italic or idiomatic text element`}</strong>{` `}<inlineCode parentName="li">{`<i></i>`}</inlineCode>{`, defines a part of text in an alternate voice or mood.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Semantic Elements`}</h2>
    <p>{`When web elements are meaningful and contextual, that means it's `}<strong parentName="p">{`semantic`}</strong>{`. Semantic elements inform assistive technologies such as screen readers of an emphasis that might not be visually accessible to all users.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Do not rely on HTML to create a visual change, collaborate with developers to correctly emphasize content.`}</p>
    </blockquote>
    <p>{`Use the strong and the emphasis elements to add semantic meaning.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-strong-element"
        }}>{`The Strong Element`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#the-emphasis-element"
        }}>{`The Emphasis Element`}</a></li>
    </ul>
    <p>{`To view a list of all the HTML elements available to add semantic meaning to copy, refer to `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element#inline_text_semantics"
      }}>{`MDN’s Semantic element list`}</a>{`.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`The Strong Element`}</h3>
    <p>{`The `}<strong>{`strong element`}</strong>{` indicates strong importance, seriousness, or urgency of the enclosed text inside the `}<inlineCode parentName="p">{`<strong></strong>`}</inlineCode>{` tags.`}</p>
    <blockquote>
      <p parentName="blockquote">{`By default web browsers, render the contents inside strong tags in `}<strong parentName="p">{`bold type`}</strong>{`.`}</p>
    </blockquote>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <ul>
      <li parentName="ul">{`Referencing the name of a page`}</li>
      <li parentName="ul">{`Representing strong importance of it's contents`}</li>
      <li parentName="ul">{`Adding importance or urgency to pieces of a sentence`}</li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <ul>
      <li parentName="ul">{`Check your `}<strong parentName="li">{`To Do List`}</strong>{` items for new tasks.`}<ul parentName="li">
          <li parentName="ul">{`Check your `}<inlineCode parentName="li">{`<strong>To Do List</strong>`}</inlineCode>{` items for new tasks.`}</li>
        </ul></li>
      <li parentName="ul">{`You can change your communication preferences on `}<strong parentName="li">{`My Profile`}</strong>{`.`}<ul parentName="li">
          <li parentName="ul">{`You can change your communication preferences on `}<inlineCode parentName="li">{`<strong>My Profile</strong>`}</inlineCode>{`.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Your payment is due`}</strong>{` on September 22, 2021.`}<ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`<strong>Your payment is due</strong>`}</inlineCode>{` on September 22, 2021.`}</li>
        </ul></li>
      <li parentName="ul">{`Remember to bring your `}<strong parentName="li">{`driver’s license`}</strong>{` to your closing appointment.`}<ul parentName="li">
          <li parentName="ul">{`Remember to bring your `}<inlineCode parentName="li">{`<strong>driver’s license</strong>`}</inlineCode>{` to your closing appointment.`}</li>
        </ul></li>
    </ul>
    <h3>{`Strong vs Bold vs CSS Styling`}</h3>
    <blockquote>
      <p parentName="blockquote">{`The `}<strong parentName="p">{`bold element`}</strong>{`, `}<strong parentName="p">{`italic element`}</strong>{` and `}<strong parentName="p">{`CSS styling`}</strong>{` are purely presentational.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">{`Use the `}<strong parentName="li">{`strong element`}</strong>{` `}<inlineCode parentName="li">{`<strong></strong>`}</inlineCode>{` to communicate importance or urgency.`}</li>
      <li parentName="ul">{`Use the `}<b>{`bold element`}</b>{` `}<inlineCode parentName="li">{`<b></b>`}</inlineCode>{` to bring attention to text without indicating that it's more important.`}<ul parentName="li">
          <li parentName="ul">{`Use for:`}<ul parentName="li">
              <li parentName="ul">{`Drawing the reader's attention to the elements contents, which aren't of special importance`}</li>
              <li parentName="ul">{`Keywords in a summary and for product names in a review`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`Use `}<strong parentName="li">{`CSS`}</strong>{` (Cascade Styling Sheet) to style or decorate text. CSS styling `}<strong parentName="li">{`does not`}</strong>{` add semantic meaning to content, unlike the strong element.`}<ul parentName="li">
          <li parentName="ul">{`For example, the `}<a href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight">{` font-weight style property`}</a>{` adds weight to text, making it appear bolder.`}</li>
        </ul></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`The Emphasis Element`}</h3>
    <p>{`Use the `}<strong parentName="p">{`emphasis`}</strong>{` element to give "stressed emphasis" to the enclosed text inside the `}<inlineCode parentName="p">{`<em></em>`}</inlineCode>{` tags, in the same way verbal stress works.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`By default, web browsers render the contents inside the emphasis tags as `}<i>{`italic type.`}</i></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A screen reader will pronounce the words inside the `}<strong parentName="p">{`emphasis`}</strong>{` element `}<inlineCode parentName="p">{`<em></em>`}</inlineCode>{` tags with an emphasis, using verbal stress.`}</p>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`As a UX Writer, it is important to know when to apply `}<strong parentName="p">{`emphasis`}</strong>{` to text because it can `}<strong parentName="p">{`change the meaning`}</strong>{` of the sentence.`}</p>
    </blockquote>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <ul>
      <li parentName="ul">{`Words that have a stressed emphasis compared to surrounding text.`}<ul parentName="li">
          <li parentName="ul">{`For example: Our family `}<em>{`loves`}</em>{` spending summers on the beach.`}</li>
        </ul></li>
      <li parentName="ul">{`Changing the meaning of a sentence.`}<ul parentName="li">
          <li parentName="ul">{`For example: We found the perfect house, let's put an offer `}<em>{`
now`}  </em>!</li>
        </ul></li>
      <li parentName="ul">{`Referencing a specific instance of microcopy within the web page that users can take action on.`}<ul parentName="li">
          <li parentName="ul">{`For example: `}<em>{`
Find Brokers Near Me`}  </em> to connect with a broker in your community.</li>
        </ul></li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <ul>
      <li parentName="ul">{`Sort by `}<em>{`Submitted`}</em>{` to see the loans you’ve already completed.`}<ul parentName="li">
          <li parentName="ul">{`Sort by `}<inlineCode parentName="li">{`<em>Submitted</em>`}</inlineCode>{` to see the loans you’ve already completed.`}</li>
        </ul></li>
      <li parentName="ul">{`Select `}<em>{`I don’t have a preference `}</em>{` from the dropdown if you change your mind.`}<ul parentName="li">
          <li parentName="ul">{`Select `}<inlineCode parentName="li">{`<em>I don’t have a preference </em>`}</inlineCode>{` from the dropdown if you change your mind.`}</li>
        </ul></li>
    </ul>
    <h3>{`Emphasis vs Italic`}</h3>
    <ul>
      <li parentName="ul">{`Use the `}<strong parentName="li">{`emphasis element`}</strong>{` `}<inlineCode parentName="li">{`<em></em>`}</inlineCode>{` to indicate stress emphasis.`}</li>
      <li parentName="ul">{`Use the `}<strong parentName="li">{`italic element`}</strong>{` `}<inlineCode parentName="li">{`<i></i>`}</inlineCode>{` for text that is set off from the normal prose, like an alternate tone or mood.`}<ul parentName="li">
          <li parentName="ul">{`Use for:`}<ul parentName="li">
              <li parentName="ul">{`Technical terms`}</li>
              <li parentName="ul">{`Transliterations`}</li>
              <li parentName="ul">{`Taxonomical designations, like: "Homo Sapiens"`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Accessibility`}</h2>
    <p>{`Use semantic HTML to ensure digital experiences are inclusive to all users and accessible by assistive technologies.`}</p>
    <p>{`Our guides are not all-inclusive. Refer to the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/2016/REC-html51-20161101/dom.html#elements"
      }}>{`Web Content Accessibility Guidelines (WCAG 2.1, Level AA)- Semantic Elements`}</a>{` for more insights.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      